import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { MENU_CLOSED, MENU_CLOSING, MENU_OPENED, MENU_OPENING } from './events';

export default el => {

    const $el = $(el);
    const $pageHeader = $('#top').eq(0);
    const $burger = $el.find('[data-burger]').eq(0);
    const $menu = $($burger.get(0).nextElementSibling);
    const $panelToggles = $menu.find('button[aria-expanded]');

    let isOpen = false;
    let isTabbingLocked = false;

    const closePanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'false') {
            return;
        }
        $toggle.attr({ 'aria-expanded': 'false' });
        const panel = $toggle.get(0).nextElementSibling;
        panel.closest('li').classList.remove('is-open');
        el.classList.remove('has-open-panel');

        gsap.killTweensOf([panel]);

        const afterClose = () => {
            panel.hidden = true;
            gsap.set([panel], { clearProps: 'transform,opacity,height' });
        };

        if (tween) {
            gsap.timeline({
                onComplete: afterClose
            })
                .to(panel, { opacity: 0, duration: 0.3 }, 0)
                .to(panel, { height: 0, duration: 0.3, ease: 'Quad.easeInOut' }, 0);
        } else {
            afterClose();
        }

    };

    const closeOpenPanel = (tween = false) => {
        $panelToggles.each(panelToggle => closePanel($(panelToggle), tween));
    };

    const openPanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'true') {
            return;
        }
        closeOpenPanel(tween);
        $toggle.attr({ 'aria-expanded': 'true' });
        const panel = $toggle.get(0).nextElementSibling;
        panel.hidden = false;
        const menuItem = panel.closest('li');
        menuItem.classList.add('is-open');
        el.classList.add('has-open-panel');
        gsap.killTweensOf([panel]);
        const afterOpen = () => {
            gsap.set([panel], { clearProps: 'transform,opacity,height' });
        };
        if (tween) {
            gsap.timeline({
                onComplete: afterOpen
            })
                .fromTo(panel, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
                .fromTo(panel, { height: 0 }, { height: 'auto', duration: 0.3, ease: 'Quad.easeInOut' }, 0)
                .to($menu.get(0), { scrollTop: 0, duration: 0.3, ease: 'Quad.easeInOut' }, 0);
        } else {
            $menu.get(0).scrollTo(0, 0);
            afterOpen();
        }
    };

    const togglePanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'true') {
            closePanel($toggle, tween);
        } else {
            openPanel($toggle, tween);
        }
    };

    const open = (tween = true) => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        Dispatch.emit(MENU_OPENING);
        $menu.get(0).style.display = 'block';
        document.body.classList.remove('menu-closing');
        document.body.classList.add('menu-opening');
        $burger.attr({ 'aria-expanded': 'true' });
        if (isTabbingLocked) {
            Viewport.releaseTabbing();
        }
        Viewport.lockTabbing($pageHeader.get(0), $burger.get(0));
        isTabbingLocked = true;
        const innerMenu = $menu.get(0).firstElementChild;
        const backdrop = $el.find('[data-backdrop]').get(0);
        gsap.killTweensOf([innerMenu, backdrop]);
        const afterOpen = () => {
            gsap.set([innerMenu, backdrop], { clearProps: 'transform,opacity' });
            document.body.classList.remove('menu-opening');
            document.body.classList.add('menu-open');
            Dispatch.emit(MENU_OPENED);
        };
        if (tween) {
            gsap.timeline({
                onComplete: afterOpen
            })
                .fromTo(backdrop, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
                .fromTo(innerMenu, { yPercent: -100 }, { yPercent: 0, duration: 0.5, ease: 'Power2.easeOut' }, 0);
        } else {
            afterOpen();
        }
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        $burger.attr({ 'aria-expanded': 'false' });
        const innerMenu = $menu.get(0).firstElementChild;
        document.body.classList.remove('menu-open');
        document.body.classList.remove('menu-opening');
        document.body.classList.add('menu-closing');
        Dispatch.emit(MENU_CLOSING);
        if (isTabbingLocked) {
            Viewport.releaseTabbing($burger.get(0));
            isTabbingLocked = false;
        }
        const backdrop = $el.find('[data-backdrop]').get(0);
        gsap.killTweensOf([innerMenu, backdrop]);
        const afterClose = () => {
            $menu.get(0).style.display = '';
            gsap.set([innerMenu, backdrop], { clearProps: 'transform,opacity' });
            document.body.classList.remove('menu-closing');
            Dispatch.emit(MENU_CLOSED);
            closeOpenPanel(false);
        };
        if (tween) {
            gsap.timeline({
                onComplete: afterClose
            })
                .to(backdrop, { opacity: 0, duration: 0.3 }, 0)
                .to(innerMenu, { yPercent: -100, duration: 0.3, ease: 'Power2.easeInOut' }, 0);
        } else {
            afterClose();
        }

    };

    const toggleMenu = (tween = true) => {
        if (isOpen) {
            close(tween);
        } else {
            open(tween);
        }
    };

    const onBurgerClick = () => {
        toggleMenu();
    };

    const onPanelToggleClick = e => {
        togglePanel($(e.triggerTarget));
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            close();
        }
    };

    const onBodyClick = e => {
        if (!isOpen) {
            return;
        }
        const { target } = e;
        if (!target.hasAttribute('data-backdrop') && (target === $burger.get(0) || $burger.get(0).contains(target) || target === el || el.contains(target))) {
            return;
        }
        close();
    };

    const init = () => {
        $burger.on('click', onBurgerClick);
        $panelToggles.on('click', onPanelToggleClick);
        document.body.addEventListener('keyup', onBodyKeyUp);
        document.body.addEventListener('click', onBodyClick);
    };

    const destroy = () => {
        close(false);
        $burger.off('click');
        $panelToggles.off('click');
        document.body.removeEventListener('keyup', onBodyKeyUp);
        document.body.removeEventListener('click', onBodyClick);
    };

    return {
        init,
        destroy
    };

};
