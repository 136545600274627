import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);
    const $menu = $($el.find('[data-burger]').get(0).nextElementSibling);
    const $panelToggles = $menu.find('button[aria-expanded]');

    let hasOpenPanel = false;
    let { scrollTop: prevScrollTop } = Viewport;

    const closePanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'false') {
            return;
        }
        $toggle.attr({ 'aria-expanded': 'false' });
        document.body.classList.remove('menu-panel-open');
        const panel = $toggle.get(0).nextElementSibling;
        const afterClose = () => {
            panel.hidden = true;
            gsap.set(panel, { clearProps: 'transform,opacity,z-index' });
        };
        gsap.killTweensOf(panel);
        if (tween) {
            gsap.timeline({
                onComplete: afterClose
            })
                .to(panel, { opacity: 0, duration: 0.2 }, 0)
                .to(panel, { y: -20, duration: 0.3, ease: 'Back.easeIn' }, 0);
        } else {
            afterClose();
        }
    };

    const closeOpenPanel = tween => {
        $panelToggles.each(panelToggle => closePanel($(panelToggle), tween));
    };

    const openPanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'true') {
            return;
        }
        closeOpenPanel(tween);
        $toggle.attr({ 'aria-expanded': 'true' });
        document.body.classList.add('menu-panel-open');
        const panel = $toggle.get(0).nextElementSibling;
        panel.hidden = false;
        hasOpenPanel = true;
        const firstLink = panel.querySelector('a');
        if (firstLink) {
            firstLink.focus();
        }
        gsap.killTweensOf(panel);
        const afterOpen = () => {
            gsap.set(panel, { clearProps: 'transform,opacity,z-index' });
        };
        if (tween) {
            gsap.timeline({
                onComplete: afterOpen
            })
                .set(panel, { zIndex: 10 })
                .fromTo(panel, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
                .fromTo(panel, { y: -20 }, { y: 0, duration: 0.5, ease: 'Back.easeOut' }, 0);
        } else {
            afterOpen();
        }
    };

    const togglePanel = ($toggle, tween = true) => {
        if ($toggle.attr('aria-expanded') === 'true') {
            closePanel($toggle, tween);
        } else {
            openPanel($toggle, tween);
        }
    };

    const onPanelToggleClick = e => {
        togglePanel($(e.triggerTarget));
    };

    const onBodyClick = e => {

        if (!hasOpenPanel) {
            return;
        }

        const { target } = e;

        if ((target === el || el.contains(target)) && !target.hasAttribute('data-backdrop')) {
            return;
        }

        closeOpenPanel();

    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (!hasOpenPanel) {
            prevScrollTop = scrollTop;
            return;
        }
        if (Math.abs(scrollTop - prevScrollTop) < 20) {
            return;
        }
        if (scrollTop > prevScrollTop) {
            closeOpenPanel();
        }
        prevScrollTop = scrollTop;
    };

    const init = () => {
        $panelToggles.on('click', onPanelToggleClick);
        document.body.addEventListener('click', onBodyClick);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        $panelToggles.off('click', onPanelToggleClick);
        document.body.removeEventListener('click', onBodyClick);
        closeOpenPanel(false);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
