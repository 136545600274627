import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import * as eventKeys from './events';

const TRANSITION_DURATION = 0.5; // seconds

let $body = $('body');
let $pageHeader = $('.page-header');
let bodyData = {
    offsetY: 0,
    theme: $body.data('theme'),
    uiTheme: $body.data('ui-theme'),
    headerTheme: $pageHeader.data('theme'),
    headerUiTheme: $pageHeader.data('ui-theme'),
};

let $themeSections = null;
let themeSectionsData = [];
let currentSectionIndex = 0;
let transitionTimer = null;
let disableTransition = false;

const updateSectionData = () => {
    themeSectionsData = [bodyData];

    $themeSections.each(item => {
        themeSectionsData.push({
            offsetY: item.offsetTop,
            theme: item.dataset.theme,
            uiTheme: item.dataset.uiTheme,
            headerTheme: item.dataset.headerTheme,
            headerUiTheme: item.dataset.headerUiTheme,
        });
    });
};

const updateTheme = themeData => {
    const body = $body.get(0);
    
    for (let i = body.classList.length - 1; i >= 0; i--) {
        const className = body.classList[i];
        if (className.startsWith('theme') || className.startsWith('ui-theme')) {
            body.classList.remove(className);
        }
    }
    
    if (transitionTimer) {
        clearTimeout(transitionTimer);
    }

    let duration = TRANSITION_DURATION;
    if (disableTransition) {
        duration = 0;
    }

    body.classList.add('color-transitioning');
    body.style.setProperty('--color-transition-duration', `${duration}s`);
    transitionTimer = setTimeout(() => {
        body.classList.remove('color-transitioning');
        body.style.removeProperty('--color-transition-duration');
    }, (duration * 1000) + 100);

    body.classList.add(themeData.theme, themeData.uiTheme);

    Dispatch.emit(eventKeys.UPDATE_PAGE_HEADER_THEME, { theme: themeData.headerTheme, uiTheme: themeData.headerUiTheme });
    Dispatch.emit(eventKeys.THEME_UPDATED, themeData);
};

const getScrollTop = () => {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
};

const resize = () => {
    updateSectionData();
    scroll();
};

const scroll = () => {
    let newCurrent = 0;
    const halfViewportHeight = Viewport.height / 2;
    const scrollTop = getScrollTop();

    themeSectionsData.forEach((data, index) => {
        if (scrollTop + halfViewportHeight < data.offsetY) {
            return;
        }

        newCurrent = index;
    });

    if (currentSectionIndex !== newCurrent) {
        updateTheme(themeSectionsData[newCurrent]);
        currentSectionIndex = newCurrent;
    }
};

const init = () => {
    $themeSections = $('[data-color-change]');

    if ($themeSections.length === 0) {
        return;
    }

    window.addEventListener('scroll', scroll, { capture: false, passive: true });

    window.addEventListener('resize', () => {
        requestAnimationFrame(resize);
    }, { capture: false, passive: true });

    window.addEventListener('orientationchange', () => {
        requestAnimationFrame(resize);
    }, { capture: false, passive: true });

    disableTransition = true;

    resize();

    disableTransition = false;
};

export default {
    init
};
