import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { loadFuse } from '../lib/async-bundles';

export default el => {

    const $el = $(el);
    const search = $el.find('[data-search]').get(0);
    const groups = $el.find('[data-group]').get();
    const items = $el.find('[data-item]').get();
    const noResults = $el.find('[data-noresults]').get(0);
    const announcer = $el.find('[data-announcer]').get(0);

    let fuse;
    let searchQuery;

    const resetSearch = () => {
        items.forEach(item => {
            item.hidden = false;
        });
        groups.forEach(group => {
            group.hidden = false;
        });
        noResults.hidden = true;
        announcer.textContent = '';
    };

    const doSearch = () => {
        if (!fuse) {
            return;
        }
        if (!searchQuery) {
            resetSearch();
            return;
        }
        const result = fuse.search(searchQuery);
        const resultItemIds = result.map(resultItem => resultItem.item.node.dataset.item);
        items.forEach(item => {
            item.hidden = resultItemIds.indexOf(item.dataset.item) === -1;
        });
        let numVisibleGroups = 0;
        groups.forEach(group => {
            if (group.querySelector('[data-item]:not([hidden])')) {
                numVisibleGroups += 1;
                group.hidden = false;
            } else {
                group.hidden = true;
            }
        });
        noResults.hidden = !!numVisibleGroups;
        announcer.textContent = announcer.dataset.text.replace('%QUERY%', searchQuery).replace('%COUNT%', result.length);
    };

    const onSearchInputKeyUp = e => {
        const { triggerTarget: input } = e;
        searchQuery = input.value.trim();
        doSearch();
    };

    const initSearch = () => {
        if (!search) {
            return;
        }
        const master = items.map(item => ({
            node: item,
            question: $(item).find('[data-question]').text(),
            answer: $(item).find('[data-answer]').text()
        }));
        $(search).on('keyup', 'input[type="search"]', onSearchInputKeyUp);
        loadFuse(({ default: Fuse }) => {
            fuse = new Fuse(master, {
                includeScore: true,
                shouldSort: true,
                threshold: 0.2,
                ignoreLocation: true,
                keys: ['question', 'answer']
            });
        });
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        initSearch();
    };

    const destroy = () => {
        if (search) {
            $(search).off('keyup');
        }
    };

    return {
        init,
        destroy
    };

};
