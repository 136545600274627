import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import Modal from '../lib/Modal';
import superagent from '../core/request';
import { COMPONENT_INIT } from '../lib/events';

export default el => {
    const nodeName = el.nodeName.toLowerCase();

    const url = nodeName === 'a' ? el.href : el.dataset.fragmentUrl;

    let modal;

    const showModal = () => {
        modal = Modal(container => {
            superagent
                .get(url)
                .then(({ text }) => {
                    let html = text;
                    // Handle any script tags found in the returned HTML
                    const $scriptTags = $(html).find('script');
                    if ($scriptTags.length) {
                        html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
                        $scriptTags.each(scriptTag => {
                            const script = document.createElement('script');
                            const attributes = scriptTag.getAttributeNames();
                            attributes.forEach(attribute => {
                                script.setAttribute(attribute, scriptTag.getAttribute(attribute));
                            });
                            script.innerHTML = scriptTag.innerHTML;
                            document.body.appendChild(script);
                        });
                    }
                    // Fade out the content, and replace
                    const content = container.querySelector('[data-content]');
                    gsap.timeline()
                        .to(content, { opacity: 0, duration: 0.15 })
                        .add(() => {
                            Components.destroy(content);
                            content.innerHTML = html;
                            Components.init(content);
                        })
                        .to(content, { opacity: 1, duration: 0.3 });
                })
                .catch(error => {
                    console.error(error);
                });
        }, () => {
            modal = null;
        });
        modal.open();
    };

    const onClick = e => {
        e.preventDefault();
        if (
            nodeName === 'a' &&
            (e.ctrlKey
                || e.shiftKey
                || e.metaKey
                || (e.button && e.button === 1))
        ) {
            // Open URL in new tab
            window.open(el.href, '_blank');
            return;
        }
        showModal();
    };

    let isKeyDown = false;

    const onKeyDown = () => {
        isKeyDown = true;
    };

    const onKeyUp = e => {
        if (!isKeyDown) {
            return;
        }
        const key = e.key || e.which || e.keyCode || null;
        if (key === 'Enter' || key === 13 || key === ' ' || key === 32) {
            e.preventDefault();
            showModal();
        }
        isKeyDown = false;
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        
        el.addEventListener('click', onClick);
        el.addEventListener('keydown', onKeyDown);
        el.addEventListener('keyup', onKeyUp);
        el.setAttribute('aria-expanded', 'false');
        el.setAttribute('tabindex', '0');
        
        if (nodeName !== 'a') {
            el.setAttribute('role', 'button');
        }
    };

    const destroy = () => {
        el.removeEventListener('click', onClick);
        el.removeEventListener('keydown', onKeyDown);
        el.removeEventListener('keyup', onKeyUp);
        
        if (modal) {
            modal.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
