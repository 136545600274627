import $ from '../core/Dom';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin(Draggable)

export default (el, props) => {
    const $el = $(el);
    const $wrapper = $el.find('[data-scroll-wrapper]');
    const $inner = $el.find('[data-scroll-inner]');
    
    let draggable = null;

    const init = () => {
        draggable = Draggable.create($inner.get(0), {
            type: "x",
            bounds: $wrapper.get(0),
            inertia: true,
        });
    };

    const destroy = () => {
        if (draggable !== null) {
            draggable.kill();
        }
    };

    return {
        init,
        destroy
    };
};
