import gsap from 'gsap';
import Viewport from '../core/Viewport';

export default (onOpen, onClose) => {

    let isOpen = false;
    let activeElement = null;
    let el;
    let closeBtn;
    let closeHandler;
    let tl;

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            closeHandler();
        }
    };

    const create = () => {
        if (el) {
            return;
        }
        const template = document.getElementById('modal');
        el = template.content.firstElementChild.cloneNode(true);
        document.body.append(el);
        closeBtn = el.querySelector('button[data-close]');
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        closeBtn.removeEventListener('click', close);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        const afterClose = () => {
            Viewport.releaseTabbing();
            if (activeElement) {
                activeElement.focus({ preventScroll: true });
            }
            activeElement = null;
            if (el) {
                el.remove();
                el = null;
            }
            if (onClose) {
                onClose(el);
            }
        };
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (tween) {
            tl = gsap.timeline({
                onComplete: afterClose
            })
                .to(el, { opacity: 0, duration: 0.15 }, 0);
        } else {
            afterClose();
        }
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        create();
        isOpen = true;
        closeBtn.addEventListener('click', close);
        el.hidden = false;
        activeElement = document.activeElement;
        Viewport.lockTabbing(el, el.querySelector('button'));
        document.body.addEventListener('keyup', onBodyKeyUp);
        if (onOpen) {
            onOpen(el);
        }
        if (tl) {
            tl.kill();
            tl = null;
        }
        tl = gsap.timeline()
            .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0);
    };

    const destroy = () => {
        close(false);
    };

    closeHandler = close;

    return {
        open,
        close,
        destroy
    };
};
