import gsap from 'gsap';
import debounce from 'lodash/debounce';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import superagent from '../core/request';
import Components from '../core/Components';
import { COMPONENT_INIT } from '../lib/events';

export default (form, props) => {

    const $form = $(form);
    let request;
    let tl;

    const {
        target: targetSelector,
        announcer: announcerSelector,
        announcerUpdatingText,
        baseUrl
    } = props || {};

    if (!targetSelector) {
        console.error('No target selector');
        return null;
    }

    if (!baseUrl) {
        console.error('No base URL');
        return null;
    }

    const announcer = $(announcerSelector).get(0);

    const updateHtml = html => {

        const $html = $('<div />').html(html);
        const target = $(targetSelector).get(0);
        const newTarget = $html.find(targetSelector).get(0);

        if (!target || !newTarget) {
            console.error('Target container not found');
            return;
        }

        const { height: targetHeight } = target.getBoundingClientRect();

        tl = gsap.timeline({
            onComplete() {
                tl = null;
            }
        })
            .to(target, { opacity: 0, duration: 0.3 })
            .add(() => {
                Components.destroy(target);
                $(target).html($(newTarget).html());
                Components.init(target);
            })
            .set(target, { overflow: 'hidden' })
            .fromTo(target, { height: targetHeight }, { height: 'auto', duration: 0.5, ease: 'Quint.easeInOut', immediateRender: false }, 'in')
            .fromTo(target, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn', immediateRender: false }, 'in')
            .set(target, { clearProps: 'all' });

        // Update announcer
        const newAnnouncer = $html.find(announcerSelector).get(0);
        if (announcer && newAnnouncer) {
            announcer.textContent = newAnnouncer.textContent;
        }

    };

    const makeAjaxRequest = (url, params) => superagent
        .get(url)
        .set('Accept', 'text/html')
        .query(params)
        .then(({ status, text: html }) => {
            if (status !== 200 || !html) {
                throw new Error();
            }
            updateHtml(html);
        })
        .catch(error => {
            console.warn(error);
        });

    const updateContent = () => {

        if (request) {
            request.abort();
            $(form).find('.loading').removeClass('loading');
        }

        // Get the base URL
        let url = new URL(baseUrl).toString();

        const params = {};

        // Get the active filter
        const activeFilter = $form.find('select#filter').val();
        if (activeFilter) {
            url = `${url}/${activeFilter}`;
        }

        // Get the search query
        const searchQuery = $form.find('input#search').val();
        if (searchQuery) {
            params.query = searchQuery;
        }

        // Get the sort order
        const sortParam = $form.find('select#sort').val();
        if (sortParam) {
            params.sort = sortParam;
        }

        // Update URL
        if (Object.keys(params).length) {
            url += `?${new URLSearchParams(params).toString()}`;
        }
        window.history.pushState(null, '', url);

        // Make AJAX request to update HTML
        request = makeAjaxRequest(url, params)
            .then(() => {
                request = null;
            });

        if (announcer && announcerUpdatingText) {
            announcer.textContent = announcerUpdatingText;
        }

        return request;

    };

    const onSelectChange = e => {

        e.preventDefault();

        const { triggerTarget: select } = e;
        const $select = $(select);
        const $parent = $select.parent();
        $parent.addClass('loading');

        // Update the visual label. This is really ugly but YOLO
        const value = $select.val();
        const label = $(`label[for="${select.id}"] span`).text().replace(/ /g, '');
        const selectedOption = $select.find(`option[value="${value}"]`).get(0);
        const firstOption = $select.find('option').get(0);
        const $value = $parent.find('[data-value]').eq(0);
        const visualLabel = selectedOption ? selectedOption.textContent : label;
        const newValue = `
             <span class="ellipsis l:hidden">
                 <span class="ellipsis">
                     ${visualLabel !== label ? `<span class="hidden sp:inline">${label}: </span>` : ''}
                     ${visualLabel}
                 </span>
             </span>
             <span class="max-l:hidden ellipsis">${selectedOption.textContent || firstOption.textContent}</span>
        `;
        $value.html(newValue);

        updateContent()
            .then(() => {
                $(select).parent().removeClass('loading');
            });
    };

    const searchChangeHandler = debounce(searchQuery => {
        updateContent()
            .then(() => {
                $form.find('input#search').parent().removeClass('loading');
            });
    }, 500);

    const onSearchKeyUp = () => {
        const $searchInput = $form.find('input#search');
        if (!$searchInput) {
            return;
        }
        let searchQuery = $searchInput.val();
        if (searchQuery.length <= 2) {
            searchQuery = '';
        }
        const currentSearchQuery = new URL(window.location.href).searchParams.get('query') || '';
        if (searchQuery === currentSearchQuery) {
            return;
        }
        $searchInput.parent().addClass('loading');
        searchChangeHandler();
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        $form.on('submit', e => e.preventDefault());
        $form.on('change', 'select', onSelectChange);
        $form.on('keyup', 'input#search', onSearchKeyUp);
    };

    const destroy = () => {
        $form.off('submit change');
        if (request) {
            request.abort();
        }
        if (tl) {
            tl.kill();
        }
    };

    return {
        init,
        destroy
    };

};
