import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import * as eventKeys from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $video = $el.find('video')
    const $fallbackImage = $el.find('[data-fallback-image]')

    const canvas = $el.find("[data-canvas-proxy]").get(0);
    const canvas2 = $el.find("[data-canvas-final]").get(0);
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const ctx2 = canvas2.getContext("2d");
    const video = $video.get(0);
    
    let isBooted = false;
    let bootTimeout = null;
    let isPlaying = false;
    let lastTimestamp = 0;
    let timestep = 1000 / 30;
    let colorComponents = [255, 0, 0];
    
    let observer = null;
    
    const init = () => {
        if (video) {
            observer = new IntersectionObserver(([{ isIntersecting }]) => {
                if (isIntersecting) {
                    if (!isBooted) {
                        boot();
                    } else {
                        restart();
                    }
                } else {
                    if (isBooted && isPlaying) {
                        pause();
                    }
                }
                
            });
            observer.observe(el);
            
        }
    };
    
    const destroy = () => {
        if (video) {
            video.removeEventListener('loadstart', onLoadStart);
            video.removeEventListener('loadedmetadata', onLoadStart);
            video.removeEventListener('loadeddata', onLoadStart);
            video.removeEventListener('canplay', onLoadStart);
            video.removeEventListener('timeupdate', onTimeUpdate);
            video.stop();
        }
        
        if (observer) {
            observer.disconnect();
            observer = null;
        }
      
        Dispatch.off(eventKeys.THEME_UPDATED, onThemeUpdated);
        Viewport.off('resize', onResize);
    };
    
    const boot = () => {
        parseElementColor();
        
        Dispatch.on(eventKeys.THEME_UPDATED, onThemeUpdated);

        $video.attr('src', $video.attr('data-src'));
        
        video.addEventListener('loadstart', onLoadStart);
        video.addEventListener('loadedmetadata', onLoadStart);
        video.addEventListener('loadeddata', onLoadStart);
        video.addEventListener('canplay', onLoadStart);
        video.addEventListener('timeupdate', onTimeUpdate);
        
        video.play();
        startTimer();

        Viewport.on('resize', onResize);
        
        isBooted = true;
    };
    
    const restart = () => {
        video.play()
    };
    
    const pause = () => {
        isPlaying = false;
        video.pause()
    };
    
    const onTimeUpdate = e => {
        const { currentTime } = e.target;

        if (currentTime && currentTime > 0.001) {
            onVideoPlay();
        }
    };

    const onLoadStart = e => {
        if (bootTimeout !== null) {
            return;
        }
        
        startTimer();
    };
    
    const startTimer = () => {
        bootTimeout = setTimeout(() => {
            cantPlay();
        }, 2000);
    };
    
    const onVideoPlay = () => {
        if (isPlaying) {
            return;
        }
        
        isPlaying = true;
        clearTimeout(bootTimeout);
        resizeCanvas();
        requestAnimationFrame(step);
    };
    
    const cantPlay = () => {
        $fallbackImage.attr('hidden', null);
        $video.attr('hidden', '');
        $el.find('canvas').attr('hidden', '');
    };
    
    const onResize = () => {
        resizeCanvas();
        draw();
    };
    
    const onThemeUpdated = () => {
        parseElementColor();
    };

    const parseElementColor = () => {
        const color = window.getComputedStyle(el).getPropertyValue("color");
        colorComponents = parseColor(color);
    };

    function parseColor(color) {
        const m = color.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
        if (m) {
            return [parseInt(m[1]), parseInt(m[2]), parseInt(m[3])];
        } else {
            return [255, 0, 0];
        }
    }

    const resizeCanvas = () => {
        canvas.width = $el.width();
        canvas2.width = $el.width();
        canvas.height = $el.height();
        canvas2.height = $el.height();
    };
    
    const step = timestamp => {
        if (!isPlaying) {
            return;
        }
        
        requestAnimationFrame(step);
        
        if (timestamp - lastTimestamp < timestep) {
            return;
        }
        lastTimestamp = timestamp;

        draw();
    };

    const draw = () => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = frame.data;

        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
            const alpha = 255 * (1 - ((green + blue + red) / 765));
            
            data[i] = colorComponents[0];
            data[i + 1] = colorComponents[1];
            data[i + 2] = colorComponents[2];
            
            data[i + 3] = alpha < 10 ? 0 : alpha; // sets opacity based on whiteness
        }

        ctx2.putImageData(frame, 0, 0);
    };

    return {
        init,
        destroy
    };
};

