import NavMobile from '../lib/NavMobile';
import NavDesktop from '../lib/NavDesktop';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);

    let nav;

    const getIsSmall = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    let isSmall = getIsSmall();

    const destroyNav = () => {
        if (!nav) {
            return;
        }
        nav.destroy();
        nav = null;
    };

    const createNav = () => {
        destroyNav();
        if (isSmall) {
            nav = NavMobile(el);
        } else {
            nav = NavDesktop(el);
        }
        nav.init();
    };

    const onBreakpoint = () => {
        const wasSmall = isSmall;
        isSmall = getIsSmall();
        if (isSmall === wasSmall) {
            return;
        }
        createNav();
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        Viewport.on('breakpoint', onBreakpoint);
        createNav();
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        destroyNav();
    };

    return {
        init,
        destroy
    };

};
