import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import ThemeHandler from './lib/ThemeHandler';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    ThemeHandler.init();
    lazySizes.init();

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

};

require('doc-ready')(init);
