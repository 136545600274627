import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import {COMPONENT_INIT} from '../lib/events';

export default el => {

    const button = el.firstElementChild;
    const content = button.nextElementSibling;

    let isExpanded = false;

    const tl = gsap.timeline({
        paused: true,
        onStart() {
            content.hidden = false;
        },
        onReverseComplete() {
            content.hidden = true;
            tl.invalidate();
        }
    })
        .fromTo(content, {
            height: 0
        }, {
            height: 'auto',
            duration: 0.3,
            ease: 'Quad.easeInOut'
        }, 0)
        .fromTo(content.firstElementChild, {
            y: 10
        }, {
            y: 0,
            duration: 0.3,
            ease: 'Quad.easeInOut'
        }, 0)
        .fromTo(content.firstElementChild, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.3,
            ease: 'Cubic.easeIn'
        }, 0);

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        tl.reverse();
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        tl.play();
    };

    const onButtonClick = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        button.addEventListener('click', onButtonClick);
    };

    const destroy = () => {
        button.removeEventListener('click', onButtonClick);
        tl.kill();
    };

    return {
        init,
        destroy
    };

};
