import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from "../lib/async-bundles";

export default (el, props) => {
    const SLIDER_SELECTOR = '[data-slides-wrapper]';
    const SLIDE_SELECTOR = '[data-slide-item]';

    const $el = $(el);
    const { disableBreakpoint } = props;

    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    const prevBtn = $el.find('[data-slideshow-btn-prev]');
    const nextBtn = $el.find('[data-slideshow-btn-next]');

    let Flickity;
    let slideshow;
    let lastWidth = 0;

    const init = () => {
        lastWidth = Viewport.width;

        loadFlickity(flickity => {
            Flickity = flickity;

            if (disableBreakpoint === null || Viewport.breakpoint.size < disableBreakpoint) {
                initFlickity();
            }

            prevBtn.on('click', onPrevClick);
            nextBtn.on('click', onNextClick);

            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };

    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            height: `${$slider.height()}px`
        });

        $slides.each(item => {
            const $item = $(item);
            $item.css({ height: $item.height(), display: 'block' });
        });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: false,
            freeScroll: true,
            contain: true,
            accessibility: true,
            freeScrollFriction: 0.045,
            dragThreshold: 15,
            groupCells: true,
            cellAlign: 'left'
        });

        slideshow.on('change', index => {
            updateButtonStatus();
        });

        updateButtonStatus();
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }

        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');

        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if (lastWidth !== Viewport.width) {
            if (disableBreakpoint === null || Viewport.breakpoint.size < disableBreakpoint) {
                initFlickity();
            } else {
                destroyFlickity();
            }

            lastWidth = Viewport.width;
        }
    };

    const onPrevClick = () => {
        if (slideshow) {
            slideshow.previous();
            updateButtonStatus();
        }
    };

    const onNextClick = () => {
        if (slideshow) {
            slideshow.next();
            updateButtonStatus();
        }
    };

    const updateButtonStatus = () => {
        const prevDisabled = slideshow.selectedIndex === 0;
        prevBtn.attr('disabled', prevDisabled ? '' : null);
        const nextDisabled = slideshow.selectedIndex >= (slideshow.slides.length - 1);
        nextBtn.attr('disabled', nextDisabled ? '' : null);
        if (prevDisabled && nextDisabled) {
            prevBtn.parent().css({ opacity: 0 });
        } else {
            prevBtn.parent().css({ opacity: 1 });
        }
    };

    return {
        init,
        destroy
    };

};
